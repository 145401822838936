import React, { useEffect, useState } from 'react';
import PlanPurchaseInfos from './components/PlanPurchaseInfos';
import PaymentMethod from './components/PaymentMethod';
import SubscriptionProcess from './components/SubscriptionProcess';
import { IconCheckAnimated } from '../../../../assets/Icons';
import { redirect } from '../../../../Utils/Index';

function PlanPurchase({ plan, onSelectedPaymentMethod, trialAvailableDays }) {
  const [planValidity, setValidity] = useState(null);
  const [planInfos, setPlanInfos] = useState(null);
  const [concludedSubscription, setConcludedSubscription] = useState(false);

  useEffect(() => {
    if (plan?.type) calcPlanValidity();
    setPlanInfos(plan);
  }, [plan]);

  function calcPlanValidity() {
    const currentDate = new Date();
    let planDays = plan.type === 'ANNUAL' ? 360 : 30;
    if (trialAvailableDays) {
      planDays = planDays + trialAvailableDays;
      currentDate.setDate(currentDate.getDate() + planDays);
    }
    plan.type === 'ANNUAL'
      ? currentDate.setFullYear(currentDate.getFullYear())
      : currentDate.setMonth(currentDate.getMonth());
    setValidity(currentDate.toLocaleDateString('pt-BR'));
  }

  function onChoicePaymentMethod(params) {
    onSelectedPaymentMethod();
    setPlanInfos({
      ...planInfos,
      payment_label: params.label,
      payment_method: params.method,
    });
  }

  return (
    <div className="plan-purchase-container">
      {!concludedSubscription && <PlanPurchaseInfos infos={planInfos} />}

      {planInfos && concludedSubscription ? (
        <div className="contracted-plan">
          <h3 className="contracted-plan-title">
            Parabéns, seu plano <span>{planInfos.label}</span> foi ativado com
            sucesso!
          </h3>

          <span>
            Agora você pode desfrutar de todos os beneficios presente no seu
            plano.
          </span>

          <IconCheckAnimated />

          <button
            type="button"
            className="btn btn-primary fw-bold"
            onClick={() => redirect('/painel/cardapio')}
          >
            Acessar cardápio
          </button>
        </div>
      ) : planInfos?.payment_method ? (
        <SubscriptionProcess
          params={planInfos}
          concludedProcess={() => setConcludedSubscription(true)}
        />
      ) : (
        <PaymentMethod
          onSelectedPayment={(params) => onChoicePaymentMethod(params)}
        />
      )}

      <div className="plan-purchase-container-validity">
        Sua assinatura permanecerá ativa até o dia: <br />
        <span>{planValidity}</span>
      </div>
    </div>
  );
}

export default PlanPurchase;
