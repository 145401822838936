import React, { useEffect, useState } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { withStyles } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import { updateHourStore, getHourStore } from '../../../services/Configuration';
import Checkbox from '@material-ui/core/Checkbox';
import { toast } from 'react-toastify';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.white,
    color: theme.palette.common.black,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableContainer = withStyles(() => ({
  root: {
    position: 'absolute',
    top: 10,
    fontSize: 12,
    right: 20,
    color: '#0000008a',
  },
}))(TableContainer);

export default function HorarioLoja({
  firstAccess = false,
  handleConfigureStore,
}) {
  const [daysWeek, setDaysWeek] = useState([
    {
      day: 'segunda',
      inicio: '18:00',
      fim: '23:30',
      aberto: false,
      label: 'Segunda-feira',
      valid: true,
    },
    {
      day: 'terca',
      inicio: '18:00',
      fim: '23:30',
      aberto: true,
      label: 'Terça-feira',
      valid: true,
    },
    {
      day: 'quarta',
      inicio: '18:00',
      fim: '23:30',
      aberto: true,
      label: 'Quarta-feira',
      valid: true,
    },
    {
      day: 'quinta',
      inicio: '18:00',
      fim: '23:30',
      aberto: true,
      label: 'Quinta-feira',
      valid: true,
    },
    {
      day: 'sexta',
      inicio: '18:00',
      fim: '23:30',
      aberto: true,
      label: 'Sexta-feira',
      valid: true,
    },
    {
      day: 'sabado',
      inicio: '16:00',
      fim: '00:30',
      aberto: true,
      label: 'Sábado',
      valid: true,
    },
    {
      day: 'domingo',
      inicio: '16:00',
      fim: '00:30',
      aberto: false,
      label: 'Domingo',
      valid: true,
    },
  ]);

  useEffect(() => {
    obterHoraLoja();
    return () => {
      setDaysWeek([]);
    };
  }, []);

  async function obterHoraLoja() {
    let resp = await getHourStore();
    if (resp.success && resp.results.length > 0) {
      let temp = resp.results[0];
      setDaysWeek([
        {
          day: 'segunda',
          inicio: temp.segunda_inicio,
          fim: temp.segunda_fim,
          aberto: temp.segunda_aberto === 1,
          label: 'Segunda-feira',
          valid: true,
        },
        {
          day: 'terca',
          inicio: temp.terca_inicio,
          fim: temp.terca_fim,
          aberto: temp.terca_aberto === 1,
          label: 'Terça-feira',
          valid: true,
        },
        {
          day: 'quarta',
          inicio: temp.quarta_inicio,
          fim: temp.quarta_fim,
          aberto: temp.quarta_aberto === 1,
          label: 'Quarta-feira',
          valid: true,
        },
        {
          day: 'quinta',
          inicio: temp.quinta_inicio,
          fim: temp.quinta_fim,
          aberto: temp.quinta_aberto === 1,
          label: 'Quinta-feira',
          valid: true,
        },
        {
          day: 'sexta',
          inicio: temp.sexta_inicio,
          fim: temp.sexta_fim,
          aberto: temp.sexta_aberto === 1,
          label: 'Sexta-feira',
          valid: true,
        },
        {
          day: 'sabado',
          inicio: temp.sabado_inicio,
          fim: temp.sabado_fim,
          aberto: temp.sabado_aberto === 1,
          label: 'Sábado',
          valid: true,
        },
        {
          day: 'domingo',
          inicio: temp.domingo_inicio,
          fim: temp.domingo_fim,
          aberto: temp.domingo_aberto === 1,
          label: 'Domingo',
          valid: true,
        },
      ]);
    }
  }

  async function salvarHoraLoja() {
    let item = {
      obj: {
        segunda: daysWeek[0],
        terca: daysWeek[1],
        quarta: daysWeek[2],
        quinta: daysWeek[3],
        sexta: daysWeek[4],
        sabado: daysWeek[5],
        domingo: daysWeek[6],
      },
    };
    if (daysWeek.every((item) => item.valid)) {
      let resp = await updateHourStore(item);
      if (resp.success) {
        toast.success('Horario alterado com sucesso!');
        if (firstAccess) handleConfigureStore(true);
      }
    } else {
      toast.error('Você possui horários inválidos.');
    }
  }

  function changeTime(day, type, value) {
    let temp = daysWeek.map((item) => {
      if (type === 'fim') {
        if (item.day === day) {
          item.valid = validTime('fim', getHourAndMinute(item.inicio), value);
          item[type] = value;
        }
      } else if (type === 'inicio') {
        if (item.day === day) {
          item.valid = validTime('inicio', getHourAndMinute(item.fim), value);
          item[type] = value;
        }
      } else {
        if (item.day === day) {
          item[type] = value;
        }
      }
      return item;
    });
    setDaysWeek(temp);
  }

  function getHourAndMinute(time) {
    return time?.length > 5 ? time.slice(0, 5) : time;
  }

  function validTime(type, original, value) {
    if (!value) return false;
    if (original === value) return false;
    return type === 'fim' ? original <= value : original >= value;
  }

  return (
    <div className="conf-store">
      <div className="vstack pb-2">
        <h3 className="fs-3 fw-bold m-0 p-0 text-primary">Horários</h3>
        <span className="fs-7 text-body-secondary">
          Configure o horário de atendimento do seu estabelecimento.
        </span>
      </div>

      <TableContainer>
        <Table aria-label="customized table">
          <TableHead>
            <TableRow>
              <StyledTableCell>Dia</StyledTableCell>
              <StyledTableCell align="center">Abre às</StyledTableCell>
              <StyledTableCell align="center">Fecha às</StyledTableCell>
              <StyledTableCell align="center">Aberto?</StyledTableCell>
            </TableRow>
          </TableHead>
          {daysWeek.map((days, index) => {
            return (
              <TableBody
                key={index}
                style={{
                  backgroundColor: index % 2 === 0 ? '#0000000a' : '#FFFFFF',
                }}
              >
                <TableRow>
                  <StyledTableCell component="th" scope="row">
                    {days.label}
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ position: 'relative' }}
                  >
                    <StyledTableContainer>Tempo</StyledTableContainer>
                    <TextField
                      id="time"
                      type="time"
                      min="00:00"
                      max="23:00"
                      value={days.inicio}
                      onChange={(e) =>
                        changeTime(days.day, 'inicio', e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }} // 5 min
                      style={{ color: 'red' }}
                      disabled={!days.aberto}
                    />
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    style={{ position: 'relative' }}
                  >
                    <StyledTableContainer>Tempo</StyledTableContainer>
                    <TextField
                      id="time"
                      type="time"
                      min="00:00"
                      max="23:00"
                      value={days.fim}
                      onChange={(e) =>
                        changeTime(days.day, 'fim', e.target.value)
                      }
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ step: 300 }} // 5 min
                      disabled={!days.aberto}
                    />
                    {!days.valid && (
                      <TableContainer
                        style={{
                          position: 'absolute',
                          bottom: 3,
                          fontSize: 12,
                          left: 0,
                          color: '#E4001B',
                        }}
                      >
                        Horário inválido.
                      </TableContainer>
                    )}
                  </StyledTableCell>
                  <StyledTableCell component="th" scope="row" align="center">
                    <table className="align-content">
                      <tbody>
                        <tr className="align-option-checked">
                          <td style={{ width: 70 }}>
                            {days.aberto ? 'Aberto' : 'Fechado'}
                          </td>
                          <td>
                            <Checkbox
                              checked={days.aberto}
                              onChange={(e) =>
                                changeTime(days.day, 'aberto', !days.aberto)
                              }
                              inputProps={{ 'aria-label': 'primary checkbox' }}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </StyledTableCell>
                </TableRow>
              </TableBody>
            );
          })}
        </Table>
      </TableContainer>
      <button
        className="btn btn-primary fw-bold max-w-fit my-3 w-100"
        onClick={() => salvarHoraLoja()}
      >
        Salvar alterações
      </button>
    </div>
  );
}
