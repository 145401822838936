import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import EventEmitter from '../../services/Event';

const useStyles = makeStyles((theme) => ({
  dl_modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  dl_paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    width: 'auto',
    padding: 0,
    minWidth: '400px',
    maxWidth: '400px',
    outline: 'none',
    borderRadius: 8,
    maxHeight: 'calc(100vh - 120px)',
  },
}));

export default function SpringModal({
  handleOpen,
  children,
  handleClose,
  customStyleModal,
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [styleMobileModal, setStyleMobileModal] = useState(null);

  useEffect(() => {
    if (handleOpen) setOpen(handleOpen);
    else setOpen(false);
    return () => setOpen(false);
  }, [handleOpen]);

  useEffect(() => {
    if (customStyleModal) setStyleMobileModal(customStyleModal);
    else setStyleMobileModal(null);
    return () => setStyleMobileModal(null);
  }, [customStyleModal]);

  useEffect(() => {
    EventEmitter.on('closeModal', () => closeModal());
    return () => EventEmitter.off('closeModal');
  });

  function closeModal() {
    setOpen(false);
    handleClose(false);
  }

  return (
    <Modal
      aria-labelledby="spring-modal-title"
      aria-describedby="spring-modal-description"
      className={classes.dl_modal}
      open={open}
      onClose={closeModal}
      closeAfterTransition
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
    >
      <div className={classes.dl_paper} style={styleMobileModal}>
        {children}
      </div>
    </Modal>
  );
}
