import React from 'react';

function Loader({ title = 'Carregando conteúdo, por favor aguarde.' }) {
  return (
    <div className="position-fixed vw-100 vh-100 bg-dark bg-opacity-50 top-0 left-0 vstack justify-content-center align-items-center gap-3 z-3">
      <span className="text-light fs-4 fw-bold">{title}</span>
      <div
        style={{ width: 32, height: 32 }}
        className="spinner-border spinner-border-sm text-light"
        role="status"
      ></div>
    </div>
  );
}

export default Loader;
