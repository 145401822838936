import React from 'react';
import QRCode from 'react-qr-code';
import { copyText } from '../../../../../Utils/Index';
import { toast } from 'react-toastify';
import CountdownTimer from '../../../../../shared/CountDownTimer/CountdownTimer';

function PixPayment({ code, expiration }) {
  function copyPixCode() {
    const response = copyText(code);
    if (!response) {
      toast.error(
        'Ocorreu um erro ao copiar o código pix, por favor tente novamente ou entre em contato conosco.'
      );
      return;
    }
    toast.success('Código PIX copiado com sucesso!');
  }

  return (
    <div className="subscription-process-flex-column">
      <QRCode
        id={
          '00020101021226820014br.gov.bcb.pix2560qrpix-h.bradesco.com.br/9d36b84f-c70b-478f-b95c-12729b90ca25520400005303986540559.905802BR5905ASAAS6009JOINVILLE62070503***63049982'
        }
        size={256}
        style={{ height: 'auto', maxWidth: '150px', width: '150px' }}
        value={code}
        viewBox={`0 0 256 256`}
      />

      <button
        className="subscription-process-copy-code"
        onClick={() => copyPixCode()}
      >
        Copiar chave PIX
      </button>

      <CountdownTimer expiryDate={expiration} />
    </div>
  );
}

export default PixPayment;
