import React from 'react';
import './CountInput.scss';
function CountInput({
  disableIncrease,
  disableDecrease,
  quantity,
  onIncrease,
  onDecrease,
}) {
  return (
    <div className="count-input align-items-center border d-flex flex-row gap-1 justify-content-between rounded-3">
      <button
        className={`count-button bg-transparent border-0 btn btn-fill btn-sm fs-5 hstack ${
          disableDecrease ? '' : 'text-primary'
        }`}
        disabled={disableDecrease}
        onClick={() => onDecrease()}
      >
        -
      </button>
      <span className="qtty-label fs-7">{quantity}</span>
      <button
        className={`count-button bg-transparent border-0 btn btn-fill btn-sm fs-5 hstack ${
          disableIncrease ? '' : 'text-primary'
        }`}
        onClick={() => onIncrease()}
        disabled={disableIncrease}
      >
        +
      </button>
    </div>
  );
}

export default CountInput;
