import React, { useContext, useEffect, useRef, useState } from 'react';
import {
  MenuContext,
  StoreContext,
  userDataContext,
} from '../../MenuProvider/MenuProvider';
import {
  getDeliveryPrice,
  maskCurrencyBRLWithoutSymbol,
  onlyNumbers,
  removeMaskPrice,
  saveInBrowserStorage,
} from '../../../Utils/Index';
import InputV2 from '../../../shared/InputV2/InputV2';
import { Form } from '@unform/web';
import * as Yup from 'yup';

function MoneyChange({ goToConfirmOrder, close }) {
  const formRef = useRef();

  const { userData } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);
  const { totalPrice, paymentMethod, setPaymentMethod } =
    useContext(MenuContext);

  const values = [
    {
      label: 'Valor do pedido:',
      field: 'orderPrice',
    },
    {
      label: 'Valor do frete:',
      field: 'deliveryPrice',
    },
    {
      label: 'Valor final:',
      field: 'orderFinalPrice',
    },
  ];

  const [loaded, setLoaded] = useState(false);

  const [prices, setPrices] = useState({
    orderPrice: '',
    deliveryPrice: '',
    orderFinalPrice: '',
  });

  useEffect(() => {
    callGetDeliveryPrice();
  }, []);

  function callGetDeliveryPrice() {
    setLoaded(false);
    const { address } = userData;
    const { codigo } = storeSettingsProvider.settings.store;
    getDeliveryPrice(onlyNumbers(address.cep), codigo).then((response) => {
      const { error, deliveryInfs } = response;
      if (error) {
        console.error('ERROR_ON_GET_DELIVERY_PRICE');
        return;
      }
      const price = deliveryInfs.preco;
      setPrices({
        orderPrice: maskCurrencyBRLWithoutSymbol(totalPrice),
        deliveryPrice: maskCurrencyBRLWithoutSymbol(price),
        orderFinalPrice: maskCurrencyBRLWithoutSymbol(price + totalPrice),
      });
      setLoaded(true);
    });
  }

  Yup.addMethod(Yup.string, 'verifyMoneyChangeValue', function (errorMessage) {
    return this.test(
      `verify-money-change-value`,
      errorMessage,
      function (value) {
        const totalPriceOrderNumber = removeMaskPrice(
          prices.deliveryPrice || 0
        );
        const numberValue = removeMaskPrice(value);
        return numberValue > 0 && numberValue > totalPriceOrderNumber;
      }
    );
  });

  async function checkForm(path) {
    try {
      const schema = Yup.object().shape({
        moneyChange: Yup.string().verifyMoneyChangeValue('Troco inválido'),
      });
      await schema.validate(path, { abortEarly: false });
      formRef.current.setErrors({});
      updateMoneyChangeAndNav();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function updateMoneyChangeAndNav() {
    paymentMethod.troco = removeMaskPrice(
      formRef.current.getData().moneyChange
    );
    setPaymentMethod(paymentMethod);
    saveInBrowserStorage('PAYMENT', paymentMethod);
    goToConfirmOrder();
  }

  return (
    <div className="vstack">
      <h3 className="fs-5 text-success mb-1 fw-bold text-center">
        Precisa de troco ?
      </h3>

      {loaded &&
        values.map((item) => {
          return (
            <div
              key={item.field}
              className="hstack w-100 justify-content-between align-items-center"
            >
              <span className="fs-7">{item.label}</span>
              <div className="hstack align-items-center">
                <span className="fs-8">R$</span>
                <span className="fw-bold text-success fs-6">
                  {prices[item.field]}
                </span>
              </div>
            </div>
          );
        })}

      <Form ref={formRef} onSubmit={(path) => checkForm(path)}>
        <InputV2
          type="text"
          label="Troco para:"
          name="moneyChange"
          id="moneyChange"
          maxLength="13"
          autoFocus={true}
          mask="CURRENCY"
          containerClass="mb-1"
          labelClass="text-success fw-bold"
          placeholder="Valor que deseja troco"
        />

        <div className="hstack w-100 mt-3 justify-content-between">
          <button onClick={() => close()} className="btn btn-outline-fill w-50">
            Voltar
          </button>
          <button className="btn btn-primary w-50">Avançar</button>
        </div>
      </Form>
    </div>
  );
}

export default MoneyChange;
