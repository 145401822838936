import React, { useContext, useEffect, useRef, useState } from 'react';
import * as Yup from 'yup';
import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import InputV2 from '../../../../shared/InputV2/InputV2';
import {
  maskCEP,
  saveInBrowserStorage,
  validStepsPassword,
} from '../../../../Utils/Index';
import { toast } from 'react-toastify';
import { userDataContext } from '../../../MenuProvider/MenuProvider';
import EventEmitter from '../../../../services/Event';
import { updateAddress, updatePassword } from '../../../../services/Consumer';
import { searchAddressByCEP } from '../../../../services/HttpService';

function UpdateUserData({ type, close }) {
  const formRef = useRef();
  const { userData, setUserData } = useContext(userDataContext);

  const [editingPassword, setEditingPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [disableInputAddress, setDisableInputAddress] = useState(true);
  const [verifiedCurrentPassword, setVerifiedCurrentPassword] = useState(false);
  const [displayCurrentPassword, setDisplayCurrentPassword] = useState(false);
  const [displayPassword, setDisplayPassword] = useState(false);
  const [displayConfirmPassword, setDisplayConfirmPassword] = useState(false);

  const [passwordSecurityStatus, setPasswordSecurityStatus] = useState({
    label: null,
    color: null,
  });
  const [lastSearchedCep, setLastSearchedCep] = useState('');

  useEffect(() => {
    setEditingPassword(type === 'PASSWORD');
  }, [type]);

  async function handleSubmitForm(data) {
    try {
      const PasswordSchema = Yup.object().shape({
        password: Yup.object().shape({
          currentPassword: Yup.string().required('Insira sua senha atual'),
          password: Yup.string().required('Senha é obrigatória'),
          passwordConfirmation: Yup.string()
            .required('Confime sua senha')
            .oneOf([Yup.ref('password'), 'Teste'], 'As senhas não conferem'),
        }),
      });

      const AddressSchema = Yup.object().shape({
        address: Yup.object().shape({
          cep: Yup.string()
            .required('CEP é obrigatório')
            .matches(/^[0-9]{5}-[0-9]{3}$/, 'CEP inválido'),
          street: Yup.string().required('Logradourdo é obrigatório'),
          number: Yup.string().required('Número é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
          neighborhood: Yup.string().required('Bairro é obrigatório'),
          complement: Yup.string(),
        }),
      });

      const schema = editingPassword ? PasswordSchema : AddressSchema;
      const isValid = await schema.validate(data, { abortEarly: false });
      if (!isValid) formRef.current.setErrors({});
      if (editingPassword) {
        callUpdateData(data.password);
        return true;
      }
      callUpdateData(getCompletAddress());
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function verifyPassword(currentPassword) {
    if (!currentPassword) {
      setVerifiedCurrentPassword(false);
      return;
    }
    setVerifiedCurrentPassword(true);
  }

  async function callUpdateData(data) {
    setLoading(true);

    const response = editingPassword
      ? await updatePassword(data)
      : await updateAddress(data);

    if (!response || !response.success) {
      setLoading(false);
      if (editingPassword && response.message === 'Senha não alterada.') {
        toast.error('Senha atual inválida');
        return;
      }
      toast.error(
        `Ocorreu um erro ao editar ${
          editingPassword ? 'sua senha' : 'seu endereço'
        }, tente novamente ou entre com nosso suporte.`
      );
      return;
    }
    const message = editingPassword ? 'Senha alterada' : 'Endereço alterado';
    toast.success(`${message} com sucesso.`);
    editingPassword ? close() : handleUpdateAddress(data);
  }

  function setStatusSecurityPassword(password) {
    let acmSecurity = 0;
    const validatorsPassword = validStepsPassword(password, true);

    Object.keys(validatorsPassword).forEach((validator) => {
      if (validatorsPassword[validator]) acmSecurity++;
    });

    setPasswordSecurityStatus(returnPasswordStatus(acmSecurity));
  }

  function returnPasswordStatus(qtyStepsSecurity) {
    let status = { label: 'Senha frágil', color: '#E4001B' };
    if (qtyStepsSecurity < 2) return status;
    else if (qtyStepsSecurity <= 4)
      status = { label: 'Senha razoável', color: '#E46E00' };
    else if (qtyStepsSecurity === 5)
      status = { label: 'Senha forte', color: '#27AE60' };

    return status;
  }

  async function getAddressByCep(cep) {
    const cepOnlyNumber = cep.replace(/\D/g, '');
    if (cepOnlyNumber?.length < 8 || cepOnlyNumber === lastSearchedCep) return;
    setLastSearchedCep(cepOnlyNumber);
    const result = await searchAddressByCEP(cepOnlyNumber);
    if (Boolean(result.cep)) {
      setDisableInputAddress(true);
      const address = {
        cep: result.cep,
        street: result.logradouro,
        neighborhood: result.bairro,
        city: result.localidade,
        state: result.estado,
        complement: '',
        number: '',
      };
      forceValueInInput({ address });
      return;
    }
    setDisableInputAddress(false);
    toast.error('Não foi possível encontrar um endereço para este CEP');
  }

  function forceValueInInput(values) {
    const fieldsValue = values;
    let scope = 'password';
    if (!editingPassword) scope = 'address';

    if (
      !Boolean(scope) ||
      !Boolean(fieldsValue) ||
      !Boolean(fieldsValue[scope])
    )
      return;

    Object.keys(fieldsValue[scope]).forEach((field) => {
      formRef.current.setFieldValue(
        `${scope}.${field}`,
        fieldsValue[scope][field]
      );
    });
  }

  function getCompletAddress() {
    const { address } = formRef.current.getData();
    address.cep = address.cep.replace(/\D/g, '');
    address.street = address.street.split('-')[0];
    return address;
  }

  function handleUpdateAddress(address) {
    if (!userData) {
      window.location.reload();
      return;
    }
    if (!address.complement) address.complement = 'N/A';
    address.cep = maskCEP(address.cep);
    userData.address = structuredClone(address);
    setUserData(userData);
    EventEmitter.emit('update-address', address);
    saveInBrowserStorage('USER_INFS', userData, false);
    close();
  }

  return (
    <div className="p-2 vstack w-100">
      <h3 className="fs-5 fw-bold m-0 p-0 text-center mb-3">
        Alterar {editingPassword ? 'senha' : 'endereço'}
      </h3>

      <Form
        ref={formRef}
        onSubmit={(path) => handleSubmitForm(path)}
        className={`vstack gap-2 ${loading ? 'pe-none opacity-75' : ''}`}
      >
        {editingPassword ? (
          <Scope path="password">
            <InputV2
              name="currentPassword"
              type={displayCurrentPassword ? 'text' : 'password'}
              label="Confirme sua senha atual"
              containerClass="position-relative"
              onUpdateValue={(text) => verifyPassword(text)}
              maxLength={75}
            >
              <button
                className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                type="button"
                style={{ top: 30, right: 4 }}
                onClick={() =>
                  setDisplayCurrentPassword(!displayCurrentPassword)
                }
              >
                <span className="material-symbols-outlined fs-5">
                  {displayCurrentPassword ? 'visibility' : 'visibility_off'}
                </span>
              </button>
            </InputV2>

            <InputV2
              name="password"
              type={displayPassword ? 'text' : 'password'}
              label="Senha"
              containerClass="position-relative"
              maxLength={75}
              onUpdateValue={(text) => setStatusSecurityPassword(text)}
              disabled={!verifiedCurrentPassword}
            >
              <button
                type="button"
                className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                style={{ top: 30, right: 4 }}
                onClick={() => setDisplayPassword(!displayPassword)}
              >
                <span className="material-symbols-outlined fs-5">
                  {displayPassword ? 'visibility' : 'visibility_off'}
                </span>
              </button>
            </InputV2>

            <InputV2
              name="passwordConfirmation"
              type={displayConfirmPassword ? 'text' : 'password'}
              label="Confirmação de senha"
              containerClass="position-relative"
              disabled={!verifiedCurrentPassword}
              maxLength={75}
            >
              <button
                type="button"
                className="z-2 btn btn-primary-fill p-0 m-0 position-absolute d-flex align-items-center justify-content-center p-1 "
                style={{ top: 30, right: 4 }}
                onClick={() =>
                  setDisplayConfirmPassword(!displayConfirmPassword)
                }
              >
                <span className="material-symbols-outlined fs-5">
                  {displayConfirmPassword ? 'visibility' : 'visibility_off'}
                </span>
              </button>
            </InputV2>

            {verifiedCurrentPassword && passwordSecurityStatus.label && (
              <span
                className="fs-7 fw-bold text-center"
                style={{ color: passwordSecurityStatus.color }}
              >
                {passwordSecurityStatus.label}
              </span>
            )}
          </Scope>
        ) : (
          <Scope path="address">
            <InputV2
              name="cep"
              type="text"
              label="CEP"
              mask={'CEP'}
              onUpdateValue={(text) => getAddressByCep(text)}
              maxLength={9}
            />
            <InputV2
              name="street"
              type="text"
              label="Endereço"
              disabled={disableInputAddress}
              maxLength={75}
            />
            <div className="hstack w-100 gap-2">
              <InputV2
                name="number"
                type="text"
                label="Número"
                maxLength={10}
              />
              <InputV2
                name="neighborhood"
                type="text"
                label="Bairro"
                disabled={disableInputAddress}
                maxLength={75}
              />
            </div>
            <InputV2
              name="complement"
              type="text"
              label="Complemento"
              maxLength={75}
            />
            <div className="hstack w-100 gap-2">
              <InputV2
                name="city"
                type="text"
                label="Cidade"
                disabled={disableInputAddress}
                maxLength={75}
              />
              <InputV2
                name="state"
                type="text"
                label="Estado"
                disabled={disableInputAddress}
                maxLength={75}
              />
            </div>
          </Scope>
        )}

        <div className="w-100 hstack justify-content-between align-items-center gap-2 mt-4">
          <button
            type="button"
            className="btn btn-outline-primary w-50"
            disabled={loading}
            onClick={() => close()}
          >
            Voltar
          </button>
          <button
            type="submit"
            className="btn btn-primary w-50"
            disabled={loading}
          >
            Salvar
          </button>
        </div>
      </Form>

      {loading && (
        <div className="vstack py-3 align-items-center gap-1">
          <span className="fs-8">Atualizando dados, por favor aguarde.</span>
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      )}
    </div>
  );
}

export default UpdateUserData;
