import { Form } from '@unform/web';
import { Scope } from '@unform/core';
import React, { useState, useRef, useContext, useEffect } from 'react';
import * as Yup from 'yup';
import { toast } from 'react-toastify';
import { searchAddressByCEP } from '../../../services/HttpService';
import { StoreContext, userDataContext } from '../../MenuProvider/MenuProvider';
import { authenticateByPhone, register } from '../../../services/Consumer';
import InputV2 from '../../../shared/InputV2/InputV2';
import { getDeliveryPrice, onlyNumbers } from '../../../Utils/Index';

function CreateClientAccount({ phone, backToSignIn, onCreateAccount }) {
  const formRef = useRef();

  const [formValues, setFormValues] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [disableFieldsAddress, setDisableFieldsAddress] = useState(true);
  const [step, setStep] = useState(1);

  const { setUserData } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  useEffect(() => {
    if (Boolean(phone)) formRef.current.setFieldValue('personal.phone', phone);
  }, [phone]);

  async function handleSubmit(data) {
    verifyIsValidField(step, data).then((isValid) => {
      if (!isValid) return;
      if (step === 1) {
        saveDataForm(step, data);
        setTimeout(() => updateFormValue(2, formValues), 50);
        setStep(2);
        return;
      }
      callRegisterUser({ ...formValues, ...data });
    });
  }

  function saveDataForm(step, data) {
    if (step === 1 && !Boolean(formValues)) setFormValues(data);
    else if (step === 1 && Boolean(formValues))
      setFormValues({ ...formValues, ...data });
    else setFormValues({ ...formValues, ...data });
  }

  async function callRegisterUser(params) {
    setIsLoading(true);
    const account = handleInfosToCreateAccount(params);
    register(account)
      .then(() => authenticate(account))
      .catch(() => toast.error('Não foi possível criar sua conta'))
      .finally(() => setIsLoading(false));
  }

  function handleInfosToCreateAccount(infos) {
    const { personal, address } = infos;
    personal.phone = onlyNumbers(personal.phone);
    address.cep = onlyNumbers(address.cep);
    return infos;
  }

  async function callGetDeliveryPrice() {
    const { address } = formValues;
    const { codigo } = storeSettingsProvider.settings.store;
    await getDeliveryPrice(onlyNumbers(address.cep), codigo, '');
  }

  function authenticate(params) {
    authenticateByPhone(params.personal.phone)
      .then((response) => {
        setUserData(response);
        callGetDeliveryPrice();
        setTimeout(() => onCreateAccount(), 100);
      })
      .catch(() => backToSignIn());
  }

  async function verifyIsValidField(step, field) {
    try {
      const PersonalSchema = Yup.object().shape({
        personal: Yup.object().shape({
          name: Yup.string().required('Nome é obrigatório'),
          phone: Yup.string()
            .required('Telefone é obrigatório')
            .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
        }),
      });

      const AddressSchema = Yup.object().shape({
        address: Yup.object().shape({
          cep: Yup.string()
            .required('CEP é obrigatório')
            .matches(/^[0-9]{5}-[0-9]{3}$/, 'CEP inválido'),
          street: Yup.string().required('Logradourdo é obrigatório'),
          number: Yup.string().required('Número é obrigatório'),
          state: Yup.string().required('Estado é obrigatório'),
          city: Yup.string().required('Cidade é obrigatório'),
          neighborhood: Yup.string().required('Bairro é obrigatório'),
          complement: Yup.string(),
        }),
      });

      let schema;
      if (step === 1) schema = PersonalSchema;
      if (step === 2) schema = AddressSchema;
      await schema.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  function backStep() {
    const nextRegisterStep = step - 1;
    if (step === 1) {
      backToSignIn();
      return;
    }
    setTimeout(() => updateFormValue(nextRegisterStep, formValues), 50);
    setStep(nextRegisterStep);
  }

  function getScopeForm(step) {
    return step === 1 ? 'personal' : 'address';
  }

  function updateFormValue(step, datas) {
    const fieldsValue = datas;
    const scope = getScopeForm(step);

    if (
      !Boolean(scope) ||
      !Boolean(fieldsValue) ||
      !Boolean(fieldsValue[scope])
    )
      return;

    Object.keys(fieldsValue[scope]).forEach((field) => {
      formRef.current.setFieldValue(
        `${scope}.${field}`,
        fieldsValue[scope][field]
      );
    });
  }

  function resetFieldForm(step) {
    const scope = getScopeForm(step);
    const form = formRef.current.getData();
    Object.keys(form[scope]).forEach((field) => {
      formRef.current.clearField(`${scope}.${field}`);
    });
  }

  async function getAddressByCep(cep) {
    const cepOnlyNumber = onlyNumbers(cep);
    if (!cepOnlyNumber || cepOnlyNumber?.length < 8) return;
    searchAddressByCEP(cepOnlyNumber)
      .then((result) => {
        const address = {
          cep: result.cep,
          street: result.logradouro,
          neighborhood: result.bairro,
          city: result.localidade,
          state: result.estado,
          complement: '',
          number: '',
        };
        setDisableFieldsAddress(true);
        setFormValues({ ...formValues, ...{ address } });
        updateFormValue(2, { address });
      })
      .catch(() => {
        resetFieldForm(2);
        setDisableFieldsAddress(false);
        toast.error('Não foi possível encontrar um endereço para este CEP');
      });
  }

  return (
    <div className="w-100 vstack justify-content-center align-items-center p-2 overflow-y-auto">
      <h1 className="w-100 text-center fs-5 fw-bold mb-2">
        {step === 1 ? 'Cadastre seu número' : 'Informe o endereço para entrega'}
      </h1>

      <Form
        ref={formRef}
        onSubmit={(path) => handleSubmit(path)}
        className="w-100 vstack justify-content-center align-items-center mt-3"
      >
        <div className="w-100 d-flex flex-column gap-2">
          {step === 1 && (
            <Scope path="personal">
              <div className="hstack gap-2">
                <InputV2
                  autoFocus={true}
                  label="Nome completo"
                  name="name"
                  type="text"
                  placeholder="Nome completo"
                  maxLength={30}
                />
                <InputV2
                  name="phone"
                  type="text"
                  label="Telefone"
                  mask={'PHONE'}
                  placeholder="Telefone"
                  maxLength={13}
                />
              </div>
            </Scope>
          )}

          {step === 2 && (
            <Scope path="address">
              <div className="hstack gap-2">
                <InputV2
                  name="cep"
                  type="text"
                  label="CEP"
                  mask="CEP"
                  maxLength={9}
                  placeholder="CEP"
                  onUpdateValue={(value) => getAddressByCep(value)}
                />

                <InputV2
                  name="street"
                  type="text"
                  label="Endereço"
                  placeholder="Endereço"
                  disabled={disableFieldsAddress}
                />
              </div>

              <div className="hstack gap-2">
                <InputV2
                  name="number"
                  type="text"
                  label="Número"
                  placeholder="Número"
                />

                <InputV2
                  name="neighborhood"
                  type="text"
                  label="Bairro"
                  placeholder="Bairro"
                  disabled={disableFieldsAddress}
                />
              </div>

              <div className="hstack gap-2">
                <InputV2
                  name="city"
                  type="text"
                  label="Cidade"
                  placeholder="Cidade"
                  disabled={disableFieldsAddress}
                />

                <InputV2
                  name="state"
                  type="text"
                  label="Estado"
                  placeholder="Estado"
                  disabled={disableFieldsAddress}
                />
              </div>

              <InputV2
                name="complement"
                type="text"
                label="Complemento"
                placeholder="Complemento"
                maxLength={200}
              />
            </Scope>
          )}
        </div>

        <div className="w-100 vstack justify-content-between mt-3 gap-2">
          <button
            type="submit"
            className={`btn btn-primary ${
              isLoading ? 'pe-none opacity-50' : ''
            }`}
            disabled={isLoading}
          >
            {step === 1 ? 'Avançar' : 'Cadastrar'}
          </button>

          <button
            type="button"
            className={`btn btn-fill ${isLoading ? 'pe-none opacity-50' : ''}`}
            disabled={isLoading}
            onClick={() => backStep()}
          >
            Voltar
          </button>
        </div>
      </Form>
    </div>
  );
}

export default CreateClientAccount;
