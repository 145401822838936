import React, { useEffect, useReducer } from 'react';
import {
  activeApp,
  authToken,
  haveSomeIntegrationInProcess,
} from '../services/Ifood';
import PincodeInput from '../shared/Pincode/PincodeInput';
import CountdownTimer from '../shared/CountDownTimer/CountdownTimer';
import { redirect } from '../Utils/Index';
import { toast } from 'react-toastify';
import { IconCheckAnimated } from '../assets/Icons';

export default function IfoodIntegration() {
  const [integration, updateIntegration] = useReducer(
    (prev, next) => {
      return { ...prev, ...next };
    },
    {
      section: 'INITIAL',
      code: '',
      isLoading: false,
      userCode: '',
      verificationUrl: '',
      verificationUrlComplete: '',
      authorizationCodeVerifier: '',
    }
  );

  useEffect(() => {
    verifyIntegrationProcess();
  }, []);

  function verifyIntegrationProcess() {
    haveSomeIntegrationInProcess().then((result) => {
      if (result?.integrationCompleted) {
        updateIntegration({ section: 'CONCLUDED', ...result });
        return;
      }
      if (result) updateIntegration({ section: 'CODE', ...result });
    });
  }

  function initializeIfoodIntegration() {
    updateIntegration({ isLoading: true });
    activeApp()
      .then((result) => updateIntegration({ section: 'CODE', ...result }))
      .catch(() => {
        toast.error(
          'Falha ao iniciar integração, tente novamente mais tarde ou entre em contato com nosso suporte'
        );
      })
      .finally(() => updateIntegration({ isLoading: false }));
  }

  function doIfoodAuthentication() {
    updateIntegration({ isLoading: true });
    authToken(
      integration.authorizationCode,
      integration.authorizationCodeVerifier
    )
      .then(() => updateIntegration({ section: 'CONCLUDED' }))
      .catch(() => {
        toast.error(
          'Falha ao completar integração requisiçã, tente novamente mais tarde ou entre em contato com nosso suporte'
        );
      })
      .finally(() => updateIntegration({ isLoading: false }));
  }

  return (
    <>
      {integration.isLoading && (
        <div className="align-items-center bg-black bg-opacity-25 fs-3 fw-bold gap-3 justify-content-center pe-none position-fixed vh-100 vstack vw-100 z-3">
          <span>Carregando integração, por favor aguarde.</span>
          <div className="spinner-border spinner-border-sm" role="status"></div>
        </div>
      )}

      <div className="hstack justify-content-center py-3">
        <div
          className="align-items-center p-2 vstack"
          style={{ maxWidth: '600px' }}
        >
          <h1 className="fs-3 fw-bold text-red-rose">
            {integration.section === 'CONCLUDED'
              ? 'Integração realizada com sucesso!'
              : 'Importe seu cardápio do iFood'}
          </h1>

          {integration.section === 'INITIAL' && (
            <>
              <h2 className="fs-6">
                Com nossa plataforma, você pode importar todos os produtos e
                categorias do seu cardápio do iFood automaticamente, sem
                esforço.
              </h2>

              <ul className="list-group-flush">
                <li className="list-group-item">✔️ Sem complicação</li>
                <li className="list-group-item">✔️ Rápido e automático</li>
                <li className="list-group-item">
                  ✔️ Seu cardápio pronto em minutos
                </li>
              </ul>

              <button
                type="submit"
                className="btn btn-primary w-100 my-3"
                onClick={() => initializeIfoodIntegration()}
              >
                Importar cardápio
              </button>
            </>
          )}

          {integration.section === 'CODE' && (
            <>
              <h1 className="fs-6 fw-bold">
                Acesse a página do painel do lojista do iFood e aprove a
                integração.
              </h1>

              <ol>
                <li>
                  Acesse a página para autorização{' '}
                  <a
                    href={integration.verificationUrlComplete}
                    target="_blank"
                    rel="noreferrer"
                  >
                    clicando aqui
                  </a>
                </li>
                <li>Autorize a integração</li>
                <li>Copie o código informado e cole no campo abaixo</li>
              </ol>

              <div className="vstack">
                <PincodeInput
                  length={8}
                  onChangeCode={(code) =>
                    updateIntegration({ authorizationCode: code })
                  }
                />
              </div>

              <div className=" my-3 w-100 vstack">
                <button
                  type="submit"
                  className="btn btn-primary w-100"
                  disabled={!integration.authorizationCode}
                  onClick={() => doIfoodAuthentication()}
                >
                  Confirmar código
                </button>

                <small className="fs-7 text-black-50 pt-1">
                  <CountdownTimer
                    label="Link válido por"
                    expiryDate={integration.validation}
                  />
                </small>
              </div>
              <div
                className="accordion accordion-flush w-100 "
                id="accordionFlushExample"
              >
                <div className="accordion-item">
                  <h2 className="accordion-header">
                    <button
                      className="accordion-button collapsed border-3 border border-danger-subtle rounded"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target="#flush-collapseOne"
                      aria-expanded="false"
                      aria-controls="flush-collapseOne"
                    >
                      Problemas para acessar a página de integração ?
                    </button>
                  </h2>
                  <div
                    id="flush-collapseOne"
                    className="accordion-collapse collapse"
                    data-bs-parent="#accordionFlushExample"
                  >
                    <div className="accordion-body">
                      <span className="mb-3 d-block">
                        Caso esteja com dificuldade para acessar a página de
                        autorização, copie e cole no seu navegador um dos links
                        abaixo e, se necessário, informe o código:
                      </span>

                      <ul className="gap-2 vstack">
                        <li>
                          Link com preenchimento automático do código:{' '}
                          <a
                            href={integration.verificationUrlComplete}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {integration.verificationUrlComplete}
                          </a>
                        </li>
                        <li>
                          Link da página de autorização:{' '}
                          <a
                            href={integration.verificationUrl}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {integration.verificationUrl}
                          </a>
                        </li>
                        <li>Código: {integration.userCode}</li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {integration.section === 'CONCLUDED' && (
            <div>
              <p>
                O processo da integração pode levar até 3 minutos, em breve você
                terá todo seu cardápio do Ifood no Delivery.Legal.
              </p>

              <IconCheckAnimated />

              <button
                className="btn btn-primary w-100 my-3 fw-bold"
                onClick={() => redirect('/painel/cardapio')}
              >
                Ir para meu cardápio
              </button>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
