import React, { useState, useRef, useEffect } from 'react';
import { isMobile } from '../../Utils/Index';

const ExpandableText = ({ weight, description }) => {
  const containerRef = useRef(null);
  const textRef = useRef(null);

  const [expanded, setExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const [descriptionHeight, setDescriptionHeight] = useState(43);

  useEffect(() => {
    if (textRef.current) {
      if (!isMobile()) {
        setIsOverflowing(false);
        setDescriptionHeight(textRef.current.clientHeight);
        return;
      }
      setIsOverflowing(
        textRef.current.clientHeight > containerRef.current.clientHeight
      );
    }
  }, [description, textRef]);

  function toggleExpadedGroup() {
    setDescriptionHeight(expanded ? 43 : textRef.current.clientHeight);
    setExpanded(!expanded);
  }

  const productWeight = () => {
    return (
      <div className="hstack gap-1 fs-7 text-secondary">
        <span>Peso:</span>
        {<span>{weight}</span>}
      </div>
    );
  };

  return (
    <div className="vstack w-100">
      {Boolean(description) && (
        <div
          ref={containerRef}
          style={{
            minHeight: 24,
            maxHeight: descriptionHeight,
            overflow: 'hidden',
          }}
        >
          <p
            ref={textRef}
            className={`m-0 fs-7 text-secondary ${expanded ? '' : ''}`}
          >
            {description}
          </p>
        </div>
      )}
      {isOverflowing ? (
        <div className="hstack w-100 align-items-center justify-content-between">
          {Boolean(weight) && productWeight()}

          <button
            className="btn btn-outline-fill btn-sm max-w-fit p-0 text-primary"
            style={{ fontSize: 12 }}
            onClick={() => toggleExpadedGroup(!expanded)}
          >
            {expanded ? 'Ver menos' : 'Ver mais'}
          </button>
        </div>
      ) : (
        <>{Boolean(weight) && productWeight()}</>
      )}
    </div>
  );
};

export default ExpandableText;
