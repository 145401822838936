import React, { useEffect, useRef, useState } from 'react';
import {
  maskCurrencyBRLWithSymbolString,
  removeMaskPrice,
} from '../../../../../Utils/Index';
import './ItemsComplements.scss';
import ConfirmOption from './ConfirmOption/ConfirmOption';
import { toast } from 'react-toastify';
import {
  getItemsByIdGroup,
  updateComplementoItem,
} from '../../../../../services/Products';
import EventEmitter from '../../../../../services/Event';
import ItemsComplementSkeleleton from '../../../SkeletonLoading/ItemsComplementSkeleton';

export default function ItemsComplements({
  idGroupItems,
  nameGroupComplement,
}) {
  const [tempItem, setTempItem] = useState(null);

  const [itemsComplement, setItemsComplement] = useState([]);
  const [loadingComplements, setLoadingComplements] = useState(true);
  const [enableButton, setEnableButton] = useState(false);
  const [enableCancelEdit, setEnableCancelEdit] = useState(false);

  const [saveIdGroupItems, setSaveIdGroupItems] = useState(0);

  const boxComplement = useRef();

  useEffect(() => {
    callGetItemsById(idGroupItems);
    setSaveIdGroupItems(idGroupItems);
    EventEmitter.emit('hiddenVerticalScroll', true);
    return () => EventEmitter.emit('hiddenVerticalScroll', false);
  }, [idGroupItems]); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (itemsComplement) {
      setEnableButton(
        itemsComplement.every((item) => item.valid) &&
          itemsComplement.some((item) => item.editing)
      );
      setEnableCancelEdit(itemsComplement.some((item) => item.editing));
    }
  }, [itemsComplement]);

  useEffect(() => {
    EventEmitter.on('buttons-items', (e) => getActionButton(e));
    return () => EventEmitter.off('buttons-items');
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  async function callGetItemsById(code) {
    setLoadingComplements(true);
    let resp = await getItemsByIdGroup(code);
    if (resp.success) {
      setItemsComplement(resp.result);
      formatInfs(resp.result);
      setLoadingComplements(false);
    }
    setLoadingComplements(false);
  }

  async function callUpdateComplementoItem(itemComplement, isDelete = false) {
    let preco = 0;

    if (typeof itemComplement.preco === 'string')
      preco = removeMaskPrice(itemComplement.preco);
    else preco = itemComplement.preco;

    const response = await updateComplementoItem(
      itemComplement.codigo,
      idGroupItems,
      itemComplement.nome,
      preco,
      !isDelete ? true : false,
      ''
    );

    if (!response || !response.success) {
      toast.error(
        `Ocorreu um erro ao ${
          isDelete
            ? 'excluir'
            : itemComplement.codigo === 0
            ? 'criar'
            : 'editar'
        } o complemento, tente novamente ou entre em contato conosco.`
      );
      return;
    }

    const msg = `Complemento ${
      isDelete
        ? 'excluido'
        : itemComplement.codigo === 0
        ? 'adicionado'
        : 'editado'
    } com sucesso.`;
    toast.success(msg);
    if (itemComplement.codigo === 0) {
      const updatedComplements = itemsComplement.map((complement) => {
        if (complement.id === itemComplement.id)
          complement.id = response.result;
        return complement;
      });
      setItemsComplement(updatedComplements);
    }
  }

  function formatInfs(items) {
    let temp = items.map((item, index) => {
      item.preco = maskCurrencyBRLWithSymbolString(item.preco);
      item.editing = false;
      item.delete = false;
      item.valid = true;
      item.id = index;
      return item;
    });
    setItemsComplement(temp.filter((item) => item.ativo > 0));
  }

  function newItem() {
    itemsComplement.unshift({
      id: 0,
      codigo: 0,
      nome: '',
      preco: '',
      descricao: '',
      saved: false,
    });
    let temp = itemsComplement.map((item, index) => {
      item.id = index;
      return item;
    });
    setItemsComplement(temp);
  }

  function editName(input, value) {
    let temp = itemsComplement.map((item) => {
      if (item.id === input.id) {
        item.nome = value;
        item.editing = true;
      }
      return item;
    });
    setItemsComplement(temp);
    valid(input);
  }

  function editPrice(input, value) {
    let temp = itemsComplement.map((item) => {
      if (item.id === input.id) {
        item.preco = maskCurrencyBRLWithSymbolString(value);
        item.editing = true;
      }
      return item;
    });
    setItemsComplement(temp);
    valid(input);
  }

  function valid(input) {
    let temp = itemsComplement.map((item) => {
      if (item.id === input.id)
        item.valid = item.preco && item.nome ? true : false;
      return item;
    });
    setItemsComplement(temp);
  }

  function changeItems(complementToSave) {
    const updatedComplements = itemsComplement.map((complement) => {
      if (complement.id === complementToSave.id) {
        callUpdateComplementoItem(complement);
        complement.editing = false;
      }
      return complement;
    });
    setItemsComplement(updatedComplements);
  }

  function confirmDeleteItem(param, deleting = true) {
    setTempItem(param);
    let temp = itemsComplement.map((item) => {
      item.delete = item.id === param.id ? deleting : item.delte;
      item.ativo = item.id === param.id ? !deleting : true;
      return item;
    });
    setItemsComplement(temp);
  }

  function deleteItem(deleteComplement) {
    if (!tempItem.dh_criacao && deleteComplement) {
      setItemsComplement(
        itemsComplement.filter((item) => item.id !== tempItem.id)
      );
    } else if (deleteComplement) {
      const temp = itemsComplement.filter(
        (item) => (item.ativo = item.id !== tempItem.id ? true : false)
      );
      setItemsComplement(temp);
      callUpdateComplementoItem(tempItem, true);
    } else {
      confirmDeleteItem(tempItem, false);
    }
  }

  function getActionButton(param) {
    if (param.includes('saveAll')) {
      let temp = itemsComplement.map((item) => {
        if (item.editing) {
          callUpdateComplementoItem(item);
          item.editing = false;
          item.saved = true;
          item.valid = true;
        }
        return item;
      });
      setItemsComplement(temp);
    } else if (param.includes('cancelAll')) {
      callGetItemsById(saveIdGroupItems);
    } else {
      EventEmitter.emit('navegateComplements', 1);
    }
  }

  return (
    <div className="h-100 position-relative pt-2 w-100">
      <div className="w-100 hstack align-items-center justify-content-between pb-2">
        <h3 className="fw-bold fs-3 m-0">{nameGroupComplement}</h3>
        <button
          className="bg-primary btn btn-primary-fill hstack p-1 round-cicle rounded-circle"
          onClick={() => newItem()}
        >
          <span className="material-symbols-outlined">add</span>
        </button>
      </div>

      <div
        style={{ paddingBottom: 128 }}
        className={`align-box-complements  ${
          !loadingComplements && !itemsComplement.length && 'empty-complements'
        }`}
      >
        {loadingComplements ? (
          <ItemsComplementSkeleleton />
        ) : itemsComplement.length ? (
          itemsComplement.map((item, index) => {
            return (
              <div
                key={index}
                ref={boxComplement}
                className={`box-items position-relative d-flex flex-column jusitfy-content-center rounded border p-2 ${
                  item.editing
                    ? 'border-warning'
                    : item.valid
                    ? 'border-success'
                    : 'border-error'
                }
                  
                  `}
              >
                {item.delete ? (
                  <ConfirmOption option={deleteItem} />
                ) : (
                  <div>
                    <button
                      className="position-absolute btn btn-primary-fill bg-transparent p-0"
                      style={{ right: 4, top: 8 }}
                      onClick={() =>
                        item.editing && item.valid
                          ? changeItems(item)
                          : confirmDeleteItem(item)
                      }
                    >
                      {
                        <span className="material-symbols-outlined">
                          {item.editing && item.valid ? 'save' : 'delete'}
                        </span>
                      }
                    </button>

                    <div className="d-flex flex-column justify-content-between gap-2">
                      <div className="vstack">
                        <span className="fs-7">
                          Nome do complemento <span className="fw-bold">*</span>
                        </span>
                        <input
                          className="input-style"
                          type="text"
                          placeholder="Nome do complemento"
                          required={true}
                          value={item.nome}
                          onChange={(e) => editName(item, e.target.value)}
                          maxLength="40"
                        />
                      </div>
                      <div className="vstack">
                        <span className="fs-7">
                          Preço <span className="fw-bold">*</span>
                        </span>
                        <input
                          className="input-style"
                          type="text"
                          placeholder="Preço"
                          required={true}
                          value={item.preco}
                          maxLength="10"
                          onChange={(e) => editPrice(item, e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <span className="empty-complements pt-3 fs-6 fw-bold">
            Adicione itens no grupo de complementos
          </span>
        )}
      </div>

      <div
        className="align-items-center bg-white bottom-0 d-inline-flex gap-2 justify-content-between left p-2 position-fixed py-2 shadow-lg"
        style={{
          width: 'calc(100% - 8px)',
        }}
      >
        <button
          type="button"
          className="btn btn-outline-dark"
          onClick={() => getActionButton('prev')}
        >
          Voltar
        </button>
        <button
          type="button"
          className={`btn btn-outline-primary ${
            !enableCancelEdit && 'pe-none opacity-50'
          }`}
          onClick={() => getActionButton('cancelAll')}
          disabled={!enableCancelEdit}
        >
          Cancelar
        </button>
        <button
          type="button"
          className={`btn btn-primary ${!enableButton && 'pe-none opacity-50'}`}
          onClick={() => getActionButton('saveAll')}
          disabled={!enableButton}
        >
          Salvar
        </button>
      </div>
    </div>
  );
}
