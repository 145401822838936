import React, { useState, useEffect, useContext } from 'react';
import InputRadio from '../../../shared/InputRadio/InputRadio';
import {
  ComplementsContext,
  MenuContext,
  StoreContext,
} from '../../MenuProvider/MenuProvider';
import { maskCurrencyBRLWithoutSymbol } from '../../../Utils/Index';
import CountInput from '../../../shared/CountInput/CountInput';

export default function Complements({
  idProduct,
  qty,
  complements,
  updateComplement,
  updateProduct,
}) {
  const [groupComplement, setGroupComplement] = useState(null);
  const [qtyComplements, setQtyComplements] = useState(1);
  const [selectedComplement, setSelectedComplement] = useState(new Map());

  const { storeSettingsProvider } = useContext(StoreContext);
  const { complementsProductSelected, setComplementsProductSelected } =
    useContext(ComplementsContext);
  const { productSelectedProvider, setProductSelectedProvider } =
    useContext(MenuContext);

  useEffect(() => {
    setGroupComplement(complements);
  }, [complements]);

  useEffect(() => {
    setQtyComplements(qty);
    updateQtyComplement(qty);
  }, [qty]);

  function toggleExpadedGroup(groupComplement) {
    updateComplement((groupComplement.expaded = !groupComplement.expaded));
  }

  function addComplement(index) {
    if (groupComplement.qtd_complement_selected >= groupComplement.qtd_maxima)
      return;

    groupComplement.items[index].qty_selected += 1;
    setGroupComplement(groupComplement);
    addComplementsOnProduct(groupComplement.items[index]);
  }

  function removeComplement(index) {
    if (groupComplement.items[index].qty_selected === 0) return;
    groupComplement.items[index].qty_selected -= 1;
    setGroupComplement(structuredClone(groupComplement));
    removeComplementsOnProduct(groupComplement.items[index]);
  }

  const isSelectedComplement = (id) => {
    if (selectedComplement.has(id)) return true;
    selectedComplement.clear();
    setSelectedComplement(new Map().set(id, true));
    return false;
  };

  function selectComplement(complement) {
    if (isSelectedComplement(complement.codigo) && groupComplement?.required)
      return;

    const response = groupComplement.items.map((productComplement) => {
      if (
        productComplement.codigo === complement.codigo &&
        !productComplement.selected
      ) {
        addComplementsOnProduct(productComplement);
        productComplement.selected = true;
      } else if (productComplement.selected) {
        removeComplementsOnProduct(productComplement);
        productComplement.selected = false;
      }

      groupComplement.is_valid = groupComplement.required
        ? groupComplement.qtd_complement_selected >= groupComplement.qtd_minima
        : true;
      return productComplement;
    });

    const updatedComplement = { ...groupComplement, ...{ items: response } };
    setGroupComplement(updatedComplement);
    updateComplement(updatedComplement);
  }

  function addComplementsOnProduct(complement) {
    groupComplement.qtd_complement_selected += 1;
    const newComplement = {
      cod_complemento: complement.codigo,
      qtd: qtyComplements,
      name: complement.nome,
      price: {
        value: complement.preco,
        formated: complement.formated_price,
        with_qty: complement.preco * qtyComplements,
      },
    };
    const createComplement = {
      id_product: groupComplement.id_product,
      complement_selected: [newComplement],
    };

    if (!complementsProductSelected.length)
      complementsProductSelected.push(createComplement);
    else if (!getProduct()) complementsProductSelected.push(createComplement);
    else {
      if (groupComplement.qtd_maxima > 1) {
        const index = findProductComplementById(complement.codigo);
        if (index >= 0) getProduct().complement_selected[index].qtd += 1;
        else insertNewComplement(newComplement);
      } else insertNewComplement(newComplement);
    }

    groupComplement.is_valid = groupComplement.required
      ? groupComplement.qtd_complement_selected >= groupComplement.qtd_minima
      : true;
    setGroupComplement(groupComplement);
    updateComplement(groupComplement);
    setOrderPrice(complement.preco, 'increase');
    setComplementsProductSelected(complementsProductSelected);
  }

  function insertNewComplement(complement) {
    getProduct().complement_selected.push(complement);
  }

  function getProduct() {
    return complementsProductSelected.find(
      (products) => products.id_product === groupComplement.id_product
    );
  }

  function findProductComplementById(idComplement) {
    return getProduct().complement_selected.findIndex(
      (complement) => complement.cod_complemento === idComplement
    );
  }

  function removeComplementsOnProduct(complement) {
    groupComplement.qtd_complement_selected -= 1;
    complementsProductSelected
      .find(
        (selectedComplement) =>
          selectedComplement.id_product === groupComplement.id_product
      )
      .complement_selected.pop();
    groupComplement.is_valid = groupComplement.required
      ? groupComplement.qtd_complement_selected >= groupComplement.qtd_minima
      : true;
    setOrderPrice(complement.preco, 'decrease');
    setComplementsProductSelected(complementsProductSelected);
    setGroupComplement(groupComplement);
    updateComplement(groupComplement);
  }

  function setOrderPrice(price, type) {
    const product = productSelectedProvider.map((product) => {
      if (product.codigo === groupComplement.id_product) {
        const priceOrder = product.count_price
          ? product.count_price.number
          : product.preco;
        const newOrderPrice =
          type === 'increase' ? priceOrder + price : priceOrder - price;
        const newOrderPriceFormated =
          maskCurrencyBRLWithoutSymbol(newOrderPrice);
        product.count_price = {
          number: newOrderPrice,
          formated: newOrderPriceFormated,
        };
      }
      return product;
    });
    setProductSelectedProvider(product);
    updateProduct(
      product.find((product) => product.codigo === groupComplement.id_product)
    );
  }

  function updateQtyComplement(qty) {
    const updateQtyComplements = complementsProductSelected.map(
      (productComplements) => {
        if (productComplements.id_product === idProduct) {
          productComplements.complement_selected.forEach((complement) => {
            complement.qtd = qty;
            complement.price.with_qty = complement.price.value * qty;
          });
        }
        return productComplements;
      }
    );
    setComplementsProductSelected(updateQtyComplements);
    updateComplement(updateQtyComplements);
  }

  return (
    <div>
      <div
        className="py-1 px-1 w-100 hstack justify-content-between bg-secondary-subtle cursor-pointer"
        onClick={() => toggleExpadedGroup(groupComplement)}
      >
        <div className="vstack">
          <h2 className="fs-6 fw-bold m-0">{groupComplement?.nome}</h2>
          <span className="border fs-8">{groupComplement?.label}</span>
        </div>

        <div className="hstack gap-2 align-items-center justify-content-center">
          {groupComplement?.required && (
            <>
              {groupComplement?.is_valid ? (
                <span className="material-symbols-outlined text-success">
                  check_circle
                </span>
              ) : (
                <div className="hstack gap-2">
                  <span className="bg-dark bg-opacity-75 p-1 rounded-2 text-white fs-9 fw-bold">
                    Obrigatório
                  </span>
                </div>
              )}
            </>
          )}
          <div className="btn btn-primary-fill p-0 m-0 align-items-center hstack">
            <span className="material-symbols-outlined">
              {groupComplement?.expaded
                ? 'keyboard_arrow_up'
                : 'keyboard_arrow_down'}
            </span>
          </div>
        </div>
      </div>

      <div className="overflow-x-hidden overflow-y-auto">
        {groupComplement?.items.map((complement, index) => {
          if (!groupComplement?.expaded) return null;
          return (
            <div
              key={complement.codigo}
              className={`cursor-pointer ${
                complement.last_item ? '' : 'border-bottom'
              }`}
              onClick={() =>
                groupComplement.qtd_maxima === 1
                  ? selectComplement(complement)
                  : null
              }
            >
              <div className="w-100 hstack px-2 justify-content-between align-items-center gap-2 py-1">
                <h5 className="fs-6 text-dark m-0 p-0">{complement.nome}</h5>
                {groupComplement.qtd_maxima === 1 ? (
                  <div className="hstack gap-2">
                    <span className="fs-7">+{complement.formated_price}</span>
                    <InputRadio
                      label=""
                      selected={complement.selected}
                      customColor={storeSettingsProvider.settings.store.cor}
                      clicked={() => null}
                    />
                  </div>
                ) : (
                  <div className="hstack gap-2 w-100 justify-content-end">
                    <span className="fs-7">+{complement.formated_price}</span>
                    <CountInput
                      disableIncrease={
                        groupComplement.qtd_complement_selected >=
                        groupComplement.qtd_maxima
                      }
                      disableDecrease={!complement.qty_selected}
                      quantity={complement.qty_selected}
                      onDecrease={() => removeComplement(index)}
                      onIncrease={() => addComplement(index)}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
