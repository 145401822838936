import React, { useState, useEffect, useContext } from 'react';
import { MenuContext, StoreContext } from '../MenuProvider/MenuProvider';
import EventEmitter from '../../services/Event';
import { useHistory } from 'react-router-dom';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import OrderPaymentMethod from './components/OrderPaymentMethod';
import MoneyChange from './components/MoneyChange';
import { saveInBrowserStorage } from '../../Utils/Index';
import SpringModal from '../../shared/Modal/Modal';

export default function MenuCheckout({ close }) {
  const history = useHistory();
  const { id } = useParams();

  const [isPaymentChoice, setIsPaymentChoice] = useState(true);

  const { setPaymentMethod } = useContext(MenuContext);
  const { storePayments } = useContext(StoreContext);

  useEffect(() => {
    EventEmitter.on('select-payment', () => setIsPaymentChoice(true));
    return () => EventEmitter.off('select-payment');
  }, []);

  function confirmPaymentMethod(payment) {
    saveInBrowserStorage('PAYMENT', payment);
    setPaymentMethod(payment);
    if (payment.cod_tipo_pagto === 15) {
      setIsPaymentChoice(false);
      return;
    }
    navToConfirmOrder();
  }

  function navToConfirmOrder() {
    history.push(`/pedido/finalizar/${id}`);
  }

  return (
    <SpringModal handleOpen={true} handleClose={() => close()}>
      <div className="w-100 vstack p-2">
        {isPaymentChoice ? (
          <OrderPaymentMethod
            payments={storePayments}
            onSelectedPayment={(payment) => confirmPaymentMethod(payment)}
            close={() => close()}
          />
        ) : (
          <MoneyChange
            goToConfirmOrder={() => navToConfirmOrder()}
            close={() => setIsPaymentChoice(true)}
          />
        )}
      </div>
    </SpringModal>
  );
}
