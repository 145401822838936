import React, { useEffect, useState } from 'react';
import { PRODUCT_DEFAULT_IMAGE_URL } from '../../../Utils/Index';
import ExpandableText from '../../../shared/ExpandableText/ExpandableText';

export default function ProductInfs({ product, close }) {
  const [image, setImage] = useState(PRODUCT_DEFAULT_IMAGE_URL);

  useEffect(() => {
    if (product.image) setImage(product.image);
  }, [product]);

  return (
    <>
      <div
        className="d-none d-md-flex position-fixed border-bottom-2 justify-content-end px-2 py-2 w-100 bg-white z-3 border-bottom"
        style={{ maxWidth: 768 }}
      >
        <button
          className="bg-dark bg-opacity-75 btn btn-fill hstack p-0 p-1 rounded-circle"
          onClick={() => close(false)}
        >
          <span className="material-symbols-outlined p-0 text-light">
            close
          </span>
        </button>
      </div>

      <div className="d-flex flex-column flex-md-row my-md-2 pt-md-5 position-relative border-bottom pb-1">
        <div
          className="d-md-none d-flex justify-content-end position-fixed px-3 py-2 w-100"
          style={{ maxWidth: 768 }}
        >
          <button
            className="bg-dark bg-opacity-75 btn btn-fill hstack p-0 p-1 rounded-circle"
            onClick={() => close(false)}
          >
            <span className="material-symbols-outlined p-0 text-light">
              close
            </span>
          </button>
        </div>

        <img
          src={image}
          className="product-image"
          height="250px"
          width="100%"
          alt="Imagem do produto"
          onError={() => setImage(PRODUCT_DEFAULT_IMAGE_URL)}
        />

        <div className="vstack p-2 ">
          <div className="vstack gap-1">
            <h1 className="fs-5 fw-bold m-0 p-0">{product.nome}</h1>
            <ExpandableText
              weight={product.peso}
              description={product.descricao}
            />
          </div>
        </div>
      </div>
    </>
  );
}
