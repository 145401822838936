import React from 'react';

function SearchProducts({ search }) {
  return (
    <div className="bg-white hstack justify-content-center p-2 w-100">
      <div className="gap-3 hstack w-100" style={{ maxWidth: 550 }}>
        <input
          className="form-control rounded-1"
          type="text"
          placeholder="O que você procura hoje ?"
          onChange={(event) => search(event.target.value)}
        />
      </div>
    </div>
  );
}

export default SearchProducts;
