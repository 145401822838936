import { getItemInBrowserStorage } from '../Utils/Index';
import { addCliente } from './Assinaturas';
import { searchAddressByCEP, httpService } from './HttpService';

export const getGeral = async () => {
  const data = await httpService('painel/gestor', 'getGeral', {});
  return data;
};

export const verifyLinkStore = async (link) => {
  const data = await httpService('painel/gestor', 'verifyLinkStore', { link });
  return data;
};

export const saveLinkStore = async (link) => {
  const data = await httpService('painel/gestor', 'saveLinkStore', { link });
  return data;
};

export const updateTimeDelivery = async (obj) => {
  const data = await httpService('painel/gestor', 'updateTimeDelivery', obj);
  return data;
};

export const getTimeDelivery = async () => {
  const data = await httpService('painel/gestor', 'getTimeDelivery', {});
  return data;
};

export const updateHourStore = async (obj) => {
  const data = await httpService('painel/gestor', 'updateHourStore', obj);
  return data;
};

export const getHourStore = async () => {
  const data = await httpService('painel/gestor', 'getHourStore', {});
  return data;
};

export const getAddressStore = async () => {
  const data = await httpService('painel/gestor', 'getAddressStore', {});
  return data;
};

export const updateAddressStore = async (
  logradouro,
  logradouro_nro,
  bairro,
  cidade,
  uf,
  cep,
  aceita_retirada
) => {
  const data = await httpService('painel/gestor', 'updateAddressStore', {
    logradouro,
    logradouro_nro,
    bairro,
    cidade,
    uf,
    cep,
    aceita_retirada,
  });
  return data;
};

export const getCompletAddress = async (cep) => {
  const data = await searchAddressByCEP(cep);
  return data;
};

export const getTaxDelivery = async () => {
  const data = await httpService('painel/gestor', 'getTaxDelivery', {});
  return data;
};

export const updateTaxDelivery = async (obj) => {
  const data = await httpService('painel/gestor', 'updateTaxDelivery', obj);
  return data;
};

export const allPaymentsMethod = async () => {
  const data = await httpService('painel/gestor', 'getPaymentsType', {});
  return data;
};

export const updatePaymentsAccept = async (paymentDetails) => {
  const data = await httpService('painel/gestor', 'setPaymentType', {
    ...paymentDetails,
  });
  return data;
};

export const setConfigAll = async (configurado) => {
  await configurePaymentAccount();
  const data = await httpService('painel/gestor', 'setConfigAll', {
    configurado,
  });
  return data;
};

export const getAllCoupons = async () => {
  const data = await httpService('painel/gestor', 'getAllCoupons', {});
  return data;
};

export const updateCoupon = async (body) => {
  const data = await httpService('painel/gestor', 'updateCoupon', { ...body });
  return data;
};

async function configurePaymentAccount() {
  const response = await getAddressStore();
  const savedInfos = getItemInBrowserStorage('STORE_INITIAL_SETTINGS');
  const params = Object.assign(savedInfos, {
    endereco: response.results.logradouro,
    endereco_nro: response.results.logradouro_nro,
    bairro: response.results.bairro,
    cidade: response.results.cidade,
    estado: response.results.uf,
    cep: response.results.cep,
    complemento: '',
  });
  await addCliente(params);
}

export const updateStoreContacts = async (body) => {
  const response = await httpService('painel/gestor', 'contacts', { ...body });
  if (response.success) return true;
  throw response;
};
