import React, { useState, useEffect, useContext } from 'react';
import { MenuContext } from '../../MenuProvider/MenuProvider';
import EventEmitter from '../../../services/Event';
import { deepClone, maskCurrencyBRLWithoutSymbol } from '../../../Utils/Index';
import { toast } from 'react-toastify';
import ProductCheckout from '../ProductCheckout';
import ProductModal from '../../../shared/Modal/ProductModal';
import './OrderList.scss';

export default function OrderList({ productsInCart, price, close }) {
  const [orderList, setOrderList] = useState([]);
  const [totalPrice, setTotalPrice] = useState('R$ 0,00');

  const { productsOrders, setProductsOrders } = useContext(MenuContext);

  useEffect(() => {
    calculateSubTotal(productsInCart);
  }, [productsInCart]);

  useEffect(() => {
    setTotalPrice(price);
  }, [price]);

  function calculateSubTotal(productsInCart) {
    const productsWithSubTotal = productsInCart.map((product) => {
      let priceProduct = 0,
        priceComplement = 0,
        totalPrice = 0;
      priceProduct = product.price.with_qty;
      if (product.complementos.length) {
        product.complementos.forEach((complement) => {
          priceComplement += complement.price.with_qty;
        });
      }
      totalPrice = priceProduct + priceComplement;
      product.sub_total = maskCurrencyBRLWithoutSymbol(totalPrice);
      return product;
    });
    setOrderList(productsWithSubTotal);
  }

  function removeProductOnCart(productToRemove) {
    if (productToRemove.qtd > 1) {
      decreaseProductQuantity(productToRemove);
      return;
    }

    let indexProductToRemove = productsOrders.findIndex(
      (product) =>
        product.product_unique_id === productToRemove.product_unique_id &&
        product.codigo === productToRemove.codigo
    );
    if (indexProductToRemove < 0) {
      toast.warning(
        'Ocorreu um erro ao localizar o produto que deseja remover, afim de evitar maiores transtornos removemos os produtos simulares.'
      );
      indexProductToRemove = productsOrders.findIndex(
        (product) => product.codigo === productToRemove.codigo
      );
    }

    productsOrders.splice(indexProductToRemove, 1);
    const productsInCart = deepClone(productsOrders);
    setProductsOrders(productsInCart);
    EventEmitter.emit('update-cart', productsInCart);
    if (!productsInCart.length) {
      EventEmitter.emit('force-close-modal');
      toast.success('Carinho esvaziado.', { position: 'bottom-left' });
    }
  }

  function decreaseProductQuantity(product) {
    const productToDecreaseQuantity = deepClone(product);
    const newProductQuantity = productToDecreaseQuantity.qtd - 1;
    const newTotalProductPrice =
      newProductQuantity * productToDecreaseQuantity.price.value;

    productToDecreaseQuantity.qtd = newProductQuantity;
    productToDecreaseQuantity.price.with_qty = newTotalProductPrice;
    productToDecreaseQuantity.price.with_qty_formated =
      maskCurrencyBRLWithoutSymbol(newTotalProductPrice);

    if (productToDecreaseQuantity.complementos.length) {
      productToDecreaseQuantity.complementos = decreaseComplementQuantity(
        productToDecreaseQuantity.complementos,
        newProductQuantity
      );
    }

    const index = productsOrders.findIndex(
      (product) =>
        product.product_unique_id ===
          productToDecreaseQuantity.product_unique_id &&
        product.codigo === productToDecreaseQuantity.codigo
    );
    productsOrders[index] = productToDecreaseQuantity;
    const productsInCart = deepClone(productsOrders);
    setProductsOrders(productsInCart);
    EventEmitter.emit('update-cart', productsInCart);
  }

  function decreaseComplementQuantity(complements, newQuantity) {
    const newComplementsQuantity = complements.map((complement) => {
      complement.qtd = newQuantity;
      const priceUpdatedWithQty = complement.price.value * newQuantity;
      complement.price.with_qty = priceUpdatedWithQty;
      return complement;
    });
    return newComplementsQuantity;
  }

  function OpenOrderCheckout() {
    EventEmitter.emit('order-checkout', true);
  }

  return (
    <ProductModal handleOpen={true} handleClose={() => close()}>
      <div className="user-cart align-items-center overflow-y-auto  vstack w-100 h-100">
        <div className="user-cart-content d-flex flex-column w-100 h-100 position-relative bg-white shadow-sm">
          <div className="cart-header d-flex justify-content-between position-fixed px-3 py-2 w-100 bg-white z-2 border-bottom">
            <span className="fw-bold fs-5">Carrinho:</span>
            <button
              className="bg-dark bg-opacity-75 btn btn-fill hstack p-0 p-1 rounded-circle"
              onClick={() => close(false)}
            >
              <span className="material-symbols-outlined p-0 text-light">
                close
              </span>
            </button>
          </div>

          <div className="px-2 d-flex flex-column cart-products">
            {orderList.map((product) => {
              return (
                <ProductCheckout
                  key={product.product_unique_id}
                  product={product}
                  removeProduct={() => removeProductOnCart(product)}
                />
              );
            })}
          </div>

          <div
            className="w-100 p-2 vstack gap-1 position-fixed bottom-0 bg-white z-3 border-top"
            style={{ maxWidth: 768, height: 90 }}
          >
            <div className="hstack w-100 justify-content-between fw-bold text-success">
              <span>Total</span>
              <div className="hstack">
                <span className="fs-8">R$</span>
                <span className="price">{totalPrice}</span>
              </div>
            </div>

            <button
              type="button"
              className="btn btn-primary w-100 fw-bold m-0"
              onClick={() => OpenOrderCheckout()}
            >
              Escolher forma de pagamento
            </button>
          </div>
        </div>
      </div>
    </ProductModal>
  );
}
