import {
  getItemInBrowserStorage,
  removeItemFromSession,
  saveInBrowserStorage,
} from '../Utils/Index';
import { httpService } from './HttpService';

export const haveSomeIntegrationInProcess = async () => {
  const store = getItemInBrowserStorage('CLIENT_DATA', false);
  if (store?.integrationCompleted) return { integrationCompleted: true };
  const session = getItemInBrowserStorage('IFOOD_INTEGRATION');
  if (session) {
    const validate = new Date(session.validation);
    if (validate > Date.now()) return session;
  }
  removeItemFromSession('IFOOD_INTEGRATION');
  return null;
};

export const activeApp = async () => {
  const data = await httpService('ifood', 'activeApp', {});
  if (!data.success) throw data;
  const fiveMinutes = 300_000;
  data.result.validation = new Date(Date.now() + fiveMinutes).toISOString();
  saveInBrowserStorage('IFOOD_INTEGRATION', data.result);
  return data.result;
};

export const authToken = async (
  authorizationCode,
  authorizationCodeVerifier
) => {
  const data = await httpService('ifood', 'authToken', {
    authorizationCode,
    authorizationCodeVerifier,
  });
  if (!data.success) throw data;
  return data;
};
