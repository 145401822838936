import React, { useState, useContext, useRef } from 'react';
import { toast } from 'react-toastify';
import EventEmitter from '../../../services/Event';
import { authenticateByPhone } from '../../../services/Consumer';
import { getDeliveryPrice } from '../../../Utils/Index';
import { StoreContext, userDataContext } from '../../MenuProvider/MenuProvider';
import InputV2 from '../../../shared/InputV2/InputV2';
import * as Yup from 'yup';
import { Form } from '@unform/web';

function SignInClient({ backStep, onAuthenticate, createAccount }) {
  const formRef = useRef();

  const { setUserData } = useContext(userDataContext);
  const { storeSettingsProvider } = useContext(StoreContext);

  const [loading, setLoading] = useState(false);

  async function checkForm(path) {
    try {
      const schema = Yup.object().shape({
        phone: Yup.string()
          .required('Telefone é obrigatório')
          .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
      });

      await schema.validate(path, { abortEarly: false });
      doAuthentication();
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  async function doAuthentication() {
    const { phone } = formRef.current.getData();
    setLoading(true);
    authenticateByPhone(phone.replace(/\D/g, ''))
      .then((result) => {
        setUserData(result);
        callGetDeliveryPrice(result);
        EventEmitter.emit('update-address', result.address);
        onAuthenticate();
        toast.success('Login realizado com sucesso');
      })
      .catch((error) => {
        if (error.code === 'UNREGISTERED_USER') {
          createAccount(phone);
          return;
        }
        handleError(error.message);
      })
      .finally(() => setLoading(false));
  }

  function handleError(message) {
    const msg =
      message === 'Falha na autenticação'
        ? 'Dados inválidos.'
        : 'Ocorreu um erro ao realizar o login. Por favor, tente novamente ou entre com nosso suporte.';
    toast.error(msg, { pauseOnHover: true });
  }

  async function callGetDeliveryPrice(userInformations) {
    const { address } = userInformations;
    const { codigo } = storeSettingsProvider.settings.store;
    await getDeliveryPrice(address.cep.replace(/\D/, ''), codigo, '');
  }

  return (
    <div className="p-2 vstack w-100">
      <div className="vstack mb-3">
        <h2 className="fw-bold text-center fs-5 mb-0">Insira o seu telefone</h2>
      </div>

      <Form ref={formRef} onSubmit={(path) => checkForm(path)}>
        <InputV2
          autoFocus={true}
          type="tel"
          label="Telefone"
          name="phone"
          id="phone"
          maxLength="13"
          mask="PHONE"
          errorMessage="Telefone inválido."
          placeholder="Telefone"
          containerClass="mb-1 "
        />

        <div className="vstack w-100 justify-content-between algin-items-center mt-4 gap-2">
          <button
            type="submit"
            disabled={loading}
            className={`btn btn-primary w-100 ${
              loading ? 'pe-none opacity-50' : ''
            }`}
          >
            Acessar
          </button>

          <button
            onClick={() => backStep(false, true)}
            disabled={loading}
            className={`btn btn-fill text-dark
              ${loading ? 'pe-none opacity-50' : ''}
              `}
          >
            Sair
          </button>
        </div>
      </Form>
    </div>
  );
}

export default SignInClient;
