import React, { useState, useEffect, useContext } from 'react';
import { MenuContext, userDataContext } from '../MenuProvider/MenuProvider';
import EventEmitter from '../../services/Event';
import {
  getItemInBrowserStorage,
  maskCurrencyBRLWithoutSymbol,
  removeItemFromSession,
  saveInBrowserStorage,
} from '../../Utils/Index';
import OrderList from './OrderList/OrderList';
import { toast } from 'react-toastify';
import MenuCheckout from '../MenuCheckout/MenuCheckout';
import { useParams } from 'react-router-dom';
import AuthenticateClient from '../Client/authentication/AuthenticateClient';

export default function Cart({ handleAuthModal, onOpenAuthModal }) {
  const [totalPriceOrder, setTotalPriceOrder] = useState('0,00');
  const [openModal, setOpenModal] = useState(false);
  const [orderCheckout, setOrderCheckout] = useState(false);

  const { setProductsOrders, productsOrders, setTotalPrice } =
    useContext(MenuContext);
  const { userData } = useContext(userDataContext);

  const { id } = useParams();

  useEffect(() => {
    getCartInSessionStorage();
    EventEmitter.on('update-cart', (product) => updateCart(product));
    EventEmitter.on('order-checkout', (display) => setOrderCheckout(display));
    EventEmitter.on('force-close-modal', () => setOpenModal(false));
    return () => {
      EventEmitter.off('update-cart');
      EventEmitter.off('order-checkout');
      EventEmitter.off('force-close-modal');
    };
  }, []); //eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (handleAuthModal) {
      setOpenModal(true);
      setOrderCheckout(true);
    }
  }, [handleAuthModal]); //eslint-disable-line react-hooks/exhaustive-deps

  function getCartInSessionStorage() {
    const cart = getItemInBrowserStorage('CART');
    if (
      !Boolean(cart) ||
      Boolean(productsOrders.length) ||
      totalPriceOrder !== '0,00'
    ) {
      updateCart();
      return;
    }

    if (cart.store !== id) {
      removeItemFromSession('CART');
      return;
    }

    updateCart(cart.products);
    setProductsOrders(cart.products);
  }

  function updateCart(products = null) {
    let productsOnCart = productsOrders;
    let totalProductPrice = 0,
      totalComplementsPrice = 0,
      totalPrice = 0;

    if (products) productsOnCart = products;

    productsOnCart.forEach((product) => {
      totalProductPrice += product.price.with_qty;
      if (product.complementos.length) {
        product.complementos.forEach((complement) => {
          totalComplementsPrice += complement.price.with_qty;
        });
      }
    });

    totalPrice = totalProductPrice + totalComplementsPrice;
    setTotalPriceOrder(maskCurrencyBRLWithoutSymbol(totalPrice));
    setTotalPrice(totalPrice);
    saveInBrowserStorage('CART', {
      products: productsOnCart,
      estimatedPrice: totalPrice,
      store: id,
    });
    setProductsOrders(productsOnCart);
  }

  function viewDetailsCart() {
    if (!productsOrders.length) {
      toast.warning('Não há itens no carrinho.');
      return;
    }

    setOpenModal(true);
  }

  function onCloseModal() {
    setOpenModal(false);
    setOrderCheckout(false);
    onOpenAuthModal();
  }

  return (
    <>
      <button
        className="rounded-5 text-white btn btn-primary-fill bg-primary position-fixed p-2 hstack gap-2 align-items-center justify-content-center"
        onClick={() => viewDetailsCart()}
        style={{ bottom: 16, right: 16, minWidth: 130 }}
      >
        <span className="material-symbols-outlined">shopping_bag</span>
        <div className="hstack">
          <span className="fs-7 fw-light">R$</span>
          <span className="fw-bold">{totalPriceOrder}</span>
        </div>
      </button>

      {openModal &&
        (orderCheckout ? (
          userData ? (
            <MenuCheckout close={() => onCloseModal()} />
          ) : (
            <AuthenticateClient
              authenticated={() => setOrderCheckout(true)}
              back={() => onCloseModal()}
            />
          )
        ) : (
          <OrderList
            productsInCart={productsOrders}
            price={totalPriceOrder}
            close={() => onCloseModal()}
          />
        ))}
    </>
  );
}
