import React, { useState, useRef } from 'react';

const PincodeInput = ({ length, onChangeCode }) => {
  const [pincode, setPincode] = useState(new Array(length).fill(''));
  const inputRefs = useRef([]);

  const handleChange = (index, event) => {
    const value = event.target.value.toUpperCase();
    if (/^[A-Z0-9]?$/.test(value)) {
      const newPincode = [...pincode];
      newPincode[index] = value;
      setPincode(newPincode);
      if (value && index < length - 1) inputRefs.current[index + 1].focus();
      if (newPincode.every((item) => Boolean(item)))
        onChangeCode(newPincode.join(''));
    }
  };

  const handleKeyDown = (index, event) => {
    if (event.key === 'Backspace' && !pincode[index] && index > 0) {
      inputRefs.current[index - 1].focus();
    }
  };

  const handlePaste = (e) => {
    e.preventDefault();
    const pastedData = e.clipboardData
      .getData('text')
      .replace(/[^A-Z0-9]/g, '')
      .slice(0, 8);
    const newCode = pastedData.split('');
    setPincode([...newCode, ...new Array(8 - newCode.length).fill('')]);
    newCode.forEach((char, index) => {
      if (inputRefs.current[index]) {
        inputRefs.current[index].value = char;
      }

      if (
        newCode.length === pincode.length &&
        newCode.every((item) => Boolean(item))
      )
        onChangeCode(newCode.join(''));
    });
  };

  return (
    <div className="d-flex gap-2 justify-content-center">
      {pincode.map((char, index) => (
        <input
          autoFocus={index === 0}
          key={index}
          type="text"
          maxLength="1"
          className="form-control text-center"
          style={{ width: '40px' }}
          value={char}
          onChange={(e) => handleChange(index, e)}
          onKeyDown={(e) => handleKeyDown(index, e)}
          ref={(el) => (inputRefs.current[index] = el)}
          onPaste={handlePaste}
        />
      ))}
    </div>
  );
};

export default PincodeInput;
