import React, { useState, useEffect } from 'react';
import { Tab, Tabs } from '@material-ui/core';
import { toast } from 'react-toastify';

export default function CategoryTab({ products, productsRef }) {
  const [categories, setCategories] = useState([]);
  const [tabIndexSelected, setTabIndexSelected] = useState(0);

  useEffect(() => {
    if (products?.length) setCategories(products);
  }, [products]);

  function onSelectCategory(category, index) {
    const items = categories.map((item) => {
      item[0].isFocused = item[0].cod_categoria === category[0].cod_categoria;
      return item;
    });
    setCategories(items);
    const categoryRef = productsRef.current.filter((element) => element);
    if (!categoryRef[index]) {
      toast.error('Não foi possível navegar até a categoria selecionada.');
      return;
    }
    const elementPosition = categoryRef[index].getBoundingClientRect().top;
    const offsetPosition = elementPosition - 140;
    window.scrollBy({ top: offsetPosition, behavior: 'smooth' });
  }

  return (
    <Tabs
      value={tabIndexSelected}
      onChange={(_, index) => setTabIndexSelected(index)}
      variant="scrollable"
      scrollButtons="auto"
    >
      {categories.map((category, index) => {
        return (
          <Tab
            key={index}
            label={category[0].categoria_nome}
            onClick={() => onSelectCategory(category, index)}
            className={`fs-7 fw-bold ${
              category[0].isFocused ? 'text-primary' : ''
            }`}
          />
        );
      })}
    </Tabs>
  );
}
