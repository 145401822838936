import React, { useEffect, useRef, useState } from 'react';
import './ContactUs.scss';

import { Form } from '@unform/web';
import InputV2 from '../../shared/InputV2/InputV2';
import * as Yup from 'yup';

import { deepClone, useWindowDimensions } from '../../Utils/Index';
import { contactSellers } from '../../services/LandingPage';
import { toast } from 'react-toastify';
import ReCAPTCHA from 'react-google-recaptcha';

export default function ContactUs() {
  const [message, setMessage] = useState('');
  const [stylesToInput, setStylesToInput] = useState({
    label: {
      fontFamily: 'MontserrattBold',
      color: '#3E3E3E',
      fontSize: 12,
    },
    leftInput: {
      marginLeft: 16,
      width: '50%',
    },
    RightInput: {
      marginRight: 16,
      width: '50%',
    },
  });
  const [lastSpacing, setLastSpacing] = useState(16);
  const [disableEmissionButton, setDisableEmissionButton] = useState(false);

  const formRef = useRef(null);
  const recaptchaRef = useRef(null);

  const { width } = useWindowDimensions();

  useEffect(() => {
    if (width > 768 && lastSpacing === 16) return;
    if (width < 768 && lastSpacing === 0) return;
    const spacing = width > 768 ? 16 : 0;
    stylesToInput.leftInput = { marginLeft: spacing };
    stylesToInput.RightInput = { marginRight: spacing };
    setStylesToInput(stylesToInput);
    setLastSpacing(spacing);
  }, [width]);

  async function verifyFormData(field) {
    try {
      const dataToContact = Yup.object().shape({
        name: Yup.string().required('Nome é obrigatório'),
        email: Yup.string()
          .required('E-mail é obrigatório')
          .matches(
            /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
            'E-mail inválido'
          ),
        phone: Yup.string()
          .required('Informe um número para contato')
          .matches(/[0-9]{2}\s[0-9]{5}-[0-9]{4}/, 'Número inválido'),
        subject: Yup.string().required('Selecione o assunto que deseja tratar'),
      });

      await dataToContact.validate(field, { abortEarly: false });
      formRef.current.setErrors({});
      sendDataToContact(deepClone(formRef.current.getData()));
      return true;
    } catch (error) {
      if (error instanceof Yup.ValidationError) {
        const errorMessages = {};
        error.inner.forEach((error) => {
          errorMessages[error.path] = error.message;
        });
        formRef.current.setErrors(errorMessages);
      }
      return false;
    }
  }

  async function getRecaptcharToken() {
    const token = await recaptchaRef.current.executeAsync();
    if (!token) {
      toast.error(
        'Ocorreu um erro ao enviar o e-mail, por favor tente novamente'
      );
      return null;
    }
    return token;
  }

  async function sendDataToContact(formData) {
    setDisableEmissionButton(true);
    const token = await getRecaptcharToken();
    if (!token) return;
    formData.message = message;
    formData.token = token;
    formData.phone = formData.phone.replace(/\D/g, '');
    contactSellers(formData)
      .then((result) => {
        toast.success(
          'E-mail enviado com sucesso, retornaremos seu contato o mais breve possível.',
          { autoClose: 10000 }
        );
        setDisableEmissionButton(false);
      })
      .catch((error) => handleErrorOnSendContact(error));
  }

  function handleErrorOnSendContact(error) {
    if (error.msg === 'HAS_OPENED_TICKET') {
      toast.warn(
        `Você possui uma solicitação aberta em ${new Date(
          error.result.dh_criacao
        ).toLocaleDateString(
          'pt-BR'
        )}, por favor aguarde, retornaremos seu contato o mais breve possível.`,
        { autoClose: 10000 }
      );
      setDisableEmissionButton(false);
      return;
    }

    toast.error(
      'Ocorreu um erro ao enviar o e-mail para os nossos vendedores, por favor tente novamente ou entre em contato conosco.'
    );
    setDisableEmissionButton(false);
    return;
  }

  return (
    <div className="form-contact">
      <div className="form-contact-container">
        <div className="form-contact__sections">
          <img
            src={`${process.env.REACT_APP_FIREBASE_URL}/assets%2Flanding_page%2Flanding_page_contactus.png?alt=media`}
            className="form-contact__sections-image"
            alt="Imagem de um homem com roupas sociais com um telefone no em seu ouvido"
            loading="lazy"
          />
          <div className="form-contact__sections__contact">
            <h2 className="form-contact__sections__contact-title">
              Fale conosco
            </h2>
            <p className="form-contact__sections__contact-description">
              E aí, ficou com alguma dúvida ou deseja falar com nossa equipe de
              vendas ?{' '}
            </p>
            <p className="form-contact__sections__contact-description">
              Preencha o formulário que entraremos em contato.
            </p>
            <Form ref={formRef} onSubmit={verifyFormData}>
              <div className="d-flex flex-column w-100 gap-2 flex-sm-row">
                <InputV2
                  containerClass="w-100"
                  label="Nome"
                  name="name"
                  type="text"
                  maxLength={50}
                />
                <InputV2
                  containerClass="w-100"
                  label="E-mail"
                  name="email"
                  type="text"
                  maxLength={50}
                />
              </div>
              <div className="d-flex flex-column w-100 gap-2 flex-sm-row">
                <InputV2
                  containerClass="w-100"
                  label="Telefone"
                  name="phone"
                  type="text"
                  mask="PHONE"
                  maxLength={13}
                />
                <InputV2
                  containerClass="w-100"
                  label="Assunto"
                  name="subject"
                  type="text"
                  maxLength={100}
                />
              </div>

              <textarea
                type="text"
                name="message"
                placeholder="Escreva sua mensagem"
                maxLength="200"
                className="complete-order-textarea"
                style={{
                  maxHeight: 80,
                  minHeight: 80,
                  width: '100%',
                  maxWidth: '100%',
                  borderColor: '#DCDCDC',
                  boxSizing: 'border-box',
                }}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <button
                type="submit"
                className={`btn btn-primary w-100 py-2 mt-3 g-recaptcha ${
                  disableEmissionButton ? 'pe-none opacity-50' : ''
                }`}
                disabled={disableEmissionButton}
              >
                Enviar mensagem
              </button>
            </Form>
          </div>
        </div>
      </div>
      <ReCAPTCHA
        ref={recaptchaRef}
        size="invisible"
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_LP}
      ></ReCAPTCHA>
    </div>
  );
}
