import React from 'react';
import './LoadingContent.scss';
import { LOGO_IMAGE_URL } from '../../Utils/Index';

function LoadingContent() {
  return (
    <div
      className="w-100 h-100 hstack justify-content-center align-items-center position-absolute"
      style={{ height: '100vh', width: '100vw' }}
    >
      <img
        src={LOGO_IMAGE_URL}
        alt="Delivery.legal logo"
        width="280px"
        height="140px"
        className="store-loading-animation"
      />
    </div>
  );
}

export default LoadingContent;
